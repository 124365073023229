@keyframes spinnerrotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loadingSpinner {
  width: 50px;
  height: 50px;
  background-image: url('../../images/loadingSpinner.png');
  background-size: contain;
  opacity: 0;
  transition: 0.5s opacity;
  animation: spinnerrotation 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  &.animating {
    opacity: 1;
  }
}
