.splitlayout {
  width: 100%;
  display: flex;
  flex-direction: column;

  @include xl {
    flex-direction: row;
  }

  .area {
    width: 100%;
    min-height: 500px;
  }
}
