$themes: (
  light: (
    color-background: #ffffff,
    color-primary: #fb8567,
    color-secondary: #784df3,
    color-secondary-light: rgba(120, 77, 243, 0.7),
    color-white: #fff,
    color-black: #000,
    color-text: #000,
    color-body: #9692a0,
    color-header-shadow: rgba(0, 0, 0, 0.2),
    color-card-background: #fff,
  ),
  dark: (
    color-background: #10062c,
    color-primary: #fb8567,
    color-secondary: #784df3,
    color-secondary-light: #784df3,
    color-white: #fff,
    color-black: #000,
    color-text: #fff,
    color-body: #b7abd4,
    color-header-shadow: rgba(0, 0, 0, 1),
    color-card-background: #20153f,
  ),
);

@mixin themify($themes: $themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}
