$headerHeight: 70px;

.mainlayout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .header {
    position: fixed;
    width: 100%;
    height: $headerHeight;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    z-index: 2;
    display: flex;
    font-size: 1.2em;

    @media print {
      display: none;
    }

    @include themify($themes) {
      background-color: lighten(themed('color-background'), 5);
      box-shadow: 0px 0px 20px themed('color-header-shadow');
    }

    .logowrapper {
      width: 100%;
      text-align: center;
      margin-top: 5px;
    }

    .themeMenu {
      cursor: pointer;
      text-align: right;
    }
  }

  .content {
    padding: 16px;
    padding-top: $headerHeight + 16px;

    @include sm {
      padding: 40px;
      padding-top: $headerHeight + 40px;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 16px;
    padding: 16px;
    align-items: center;
    text-align: center;

    ul {
      list-style: none;
      padding: 0px;
      margin: 0px;

      li {
        padding: 8px 0 0 0;
      }
    }

    .item {
      padding: 16px;
    }

    @include sm {
      padding: 40px;
    }

    @include md {
      text-align: left;
      flex-direction: row;
    }

    @include themify($themes) {
      background-color: darken(themed('color-background'), 2);
    }
  }

  .textwrapper {
    max-width: 960px;
    margin: 0 auto;
  }

  .badgeTicketText {
    font-weight: 500;
    font-size: 1.1em;

    margin-top: 20px;
    text-align: center;

    padding: 20px;

    @include themify($themes) {
      background-color: themed('color-secondary');
      color: themed('color-white');
    }
  }
}
