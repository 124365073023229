.eventList {
  display: grid;
  column-gap: 30px;
  row-gap: 64px;
  justify-items: center;

  @include sm {
    column-gap: 30px;
    row-gap: 30px;
    grid-template-columns: 1fr 1fr;
  }

  @include lg {
    column-gap: 30px;
    row-gap: 30px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include xxl {
    column-gap: 30px;
    row-gap: 30px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

a .eventItem {
  &:hover {
    transition: 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) transform;
    cursor: pointer;
    transform: scale(0.98);
  }
}

.eventItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;

  &.full {
    box-shadow: none;
    border-radius: 10px;

    .image {
      height: 500px;
    }
  }

  @include themify($themes) {
    background-color: themed('color-card-background');
  }

  .image {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .info {
    width: 100%;
    height: 47%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px;

    .title {
      font-size: 1.1em;
      font-weight: 600;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .top {
      display: flex;
      flex-direction: column;
      padding-bottom: 16px;

      * {
        padding: 2px;
      }

      .location {
        font-size: 0.9em;
        line-height: 0.9em;
        @include themify($themes) {
          color: themed('color-body');
        }
      }

      .date {
        line-height: 0.9em;
        font-size: 0.9em;
        @include themify($themes) {
          color: themed('color-secondary-light');
        }
      }
    }

    .description {
      margin: 10px 0px;
      font-size: 0.9em;
      overflow: hidden;
      text-align: left;
      opacity: 0.6;

      @include themify($themes) {
        color: themed('color-body');
      }
    }

    .seatPriceBox {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin: 8px 0;
      padding: 8px 0 0 0;

      @include themify($themes) {
        border-top: 1px rgba(themed('color-body'), 0.3) solid;

        &.doubleLined {
          padding: 8px 0;
          border-bottom: 1px rgba(themed('color-body'), 0.3) solid;
        }
      }

      .item {
        text-align: center;
        width: 100%;
        .headline {
          font-size: 0.9em;
        }
        .value {
          @include themify($themes) {
            color: themed('color-primary');
          }
          font-weight: 600;
        }
      }
    }

    .locationBox {
      display: flex;
      padding: 32px 16px;
      justify-items: center;
      flex-direction: column;

      @include md {
        flex-direction: row;
      }

      .item {
        cursor: pointer;
        padding: 24px;
        border-radius: 16px;
        margin: 8px 0 8px 0;
        width: 100%;

        @include md {
          margin: 0 16px 0 16px;
        }

        @include themify($themes) {
          border: 1px rgba(themed('color-body'), 0.1) solid;
        }

        &:hover {
          @include themify($themes) {
            color: #ffffff;
            background-color: themed('color-secondary');
          }
        }

        &.active {
          color: #ffffff;
          cursor: default;
          @include themify($themes) {
            background-color: themed('color-primary');
          }
        }
      }
    }
  }
}

.map {
  padding: 32px 0 0 0;
  width: 100%;
  height: 500px;
  overflow: hidden;

  @include lg {
    padding: 0 0 0 32px;
    height: 100%;
  }
}
