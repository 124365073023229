@import 'theme.scss';
@import 'fonts.scss';
@import 'typography.scss';
@import 'responsive.scss';
@import 'layoutStyles.scss';
@import 'componentStyles.scss';

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  font-family: sans-serif;
  font-size: 1.2em;
  @include themify($themes) {
    background-color: themed('color-background');
    color: themed('color-text');
  }
}

html,
body {
  padding: 0;
  margin: 0;
}

a {
  @include themify($themes) {
    color: themed('color-primary');
    text-decoration: none;

    &:hover {
      color: lighten($color: themed('color-primary'), $amount: 10);
    }
  }
}
